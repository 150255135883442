<template>
  <div class="video-comment">
    <div class="title">Thảo luận</div>
    <div class="form-data align-items-center">
      <img :src="user.avatar || NoAvatar" alt="avatar" class="avatar rounded-circle mr-2" />
      <div class="content mr-3">
        <input class="comment-input w-100" :placeholder="placeholder" v-model="form.content" @keyup.enter="sendComment" @input="active = true" @focus="active = true" @blur="active = false" />
      </div>
      <span class="action" :class="{'active': active}" @click="sendComment" v-html="require('~/assets/mlearn/icons/comment/send-comment.svg?raw')"></span>
    </div>
    <div class="line mt-3"></div>
    <div class="list-comment scrollbar">
      <NoData v-if="!listData.length && !loading"/>
      <Item v-for="message in listData" :key="message.id" :item="message" class="item"/>
      <Loading v-if="loading"/>
    </div>
    <div class="text-center" v-if="listData.length < query.total" ><span class="view-more pointer" @click="$fetch()">Xem thêm</span></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import NoAvatar from '~/assets/mlearn/icons/no-avatar.svg'
import Item from '~/components/mlearn/comment/item.vue'
import Resource from '~/common/api/resource'
const commentResource = new Resource('library/comment')
export default {
  components: {
    Item
  },
  props: {
    placeholder: {
      type: String,
      default: 'Bình luận của bạn',
      required: false
    },
    objectId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      NoAvatar,
      listData: [],
      form: {
        object_id: this.objectId,
        object_type: 'VIDEO',
        content: ''
      },
      query: {
        object_id: this.objectId,
        object_type: 'VIDEO',
        offset: 0,
        limit: 5,
        total: 0
      },
      active: false,
      loading: false,
      processing: false
    }
  },
  async fetch () {
    const data = await commentResource.list(this.query)
    this.query.total = data.total
    this.query.offset += data.data.length
    this.listData.push(...data.data)
  },
  computed: {
    ...mapState([
      'user',
      'isMobile'
    ])
  },
  methods: {
    sendComment () {
      if (!this.user) {
        this.$router.push('/user/login')
        return
      }
      if (this.processing) { return }
      this.form.content = this.form.content.trim()
      if (!this.form.content) {
        this.active = false
        return
      }
      this.processing = true
      commentResource.store(this.form)
        .then((res) => {
          this.listData.unshift(res.data)
        })
        .finally(() => {
          this.processing = false
          this.form.content = ''
          this.active = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .video-comment{
    .title{
      font-weight: 500;
      font-size: 20px;
      padding-bottom: 12px;
      @media (max-width:576px) {
        font-size: 16px;
        padding-bottom: 8px;
      }
    }
    .form-data{
      .avatar{
        width:36px;
        height:36px;
        @media (max-width:576px) {
          width:30px;
          height:30px;
        }
      }
      .content{
        .comment-input{
          background: #EBEBEB;
          border-radius: 22px;
          font-size: 14px;
          padding:14px 20px;
          @media (max-width:576px) {
            padding:7px 10px;
          }
          &::placeholder{
            color: #6B6D70;
          }
        }
      }
    }
    .line{
      border-top: 1px solid #EBEBEB;
    }
    .list-comment{
      max-height: 350px;
      @media (max-width:576px) {
        max-height: 250px;
      }
      .item{
        padding-top: 12px;
        padding-bottom: 12px;
        @media (max-width:576px) {
          padding-top: 8px;
          padding-bottom: 8px;
        }
      }
    }
    .view-more{
      color: #004390;
      font-weight: 500;
      padding-bottom: 24px;
      font-size: 16px;
      @media (max-width:576px) {
        padding-bottom: 16px;
        font-size: 14px;
      }
    }
  }
</style>
<style lang="scss">
  .video-comment{
    .form-data{
      .action{
        svg{
          @media (max-width:576px) {
            width:20px;
          }
        }
      }
    }
  }
</style>
