<template>
  <div v-if="item">
    <div class="document-item-list position-relative pointer" v-if="viewList">
      <LockFile v-if="!item.is_free"/>
      <div class="form-data align-items-start">
        <img v-if="item.file_type === 'application/pdf'" src="~/mlearn/icons/document/pdf.svg" alt="file" class="file-type"/>
        <img v-else src="~/mlearn/icons/document/doc.svg" alt="file" class="file-type"/>
        <div class="content" :title="item.name" @click="redirectDetail">
          <div class="document-name display-html">{{item.name}}</div>
          <div class="document-subtitle display-html display-1-line">{{item.class_name}} &#8226; {{item.category_name}}</div>
        </div>
      </div>
      <div class="document-line"></div>
      <div class="row no-gutters">
        <div class="col-6">
          <div class="document-source text-truncate" :title="item.source">
            Nguồn: {{ item.source }}
          </div>
        </div>
        <div class="col-6">
          <button class="document-action bg-white text-center float-right" :class="{'active': item.is_saved}" @click="openDropdown">
            <span class="mr-1" v-html="require('~/assets/mlearn/icons/document/save.svg?raw')"></span>
            <span>{{item.is_saved ? 'Bỏ lưu' : 'Lưu'}}</span>
          </button>
          <Playlist v-if="action" field="file_id" :item="item" :playlist="playlist" :open="open" @close="open = false" @addData="addData" @showError="showError" class-button="right-0" />
        </div>
      </div>
    </div>
    <div class="document-item-detail" v-else>
      <div class="form-data">
        <template v-if="item.file_type === 'application/pdf'" >
          <img src="~/mlearn/icons/document/pdf.svg" alt="file" class="file-type"/>
        </template>
        <template v-else>
          <img src="~/mlearn/icons/document/doc.svg" alt="file" class="file-type"/>
        </template>
        <div class="content ml-3 ml-lg-4">
          <div class="d-flex align-items-center pointer" :title="item.name" @click="redirectDetail">
            <div class="document-name display-html display-1-line">{{item.name}}</div>
            <img v-if="!item.is_free" class="lock" src="~/mlearn/icons/document/lock-detail.svg" alt="Nội dung cần trả phí" />
          </div>
          <div class="document-subtitle"><span>{{item.class_name}}</span> &#8226; <span>{{item.category_name}}</span></div>
          <div class="document-source">Nguồn2: {{ item.source }}</div>
        </div>
        <div class="d-flex align-items-center">
          <div class="text-center action pointer" :class="{'active': item.is_saved}" @click="openDropdown">
            <div v-html="require('~/assets/mlearn/icons/document/save.svg?raw')"></div>
            <div>{{item.is_saved ? 'Bỏ lưu' : 'Lưu'}}</div>
          </div>
          <Playlist v-if="action" field="file_id" :item="item" :playlist="playlist" :open="open" @close="open = false" @addData="addData" @showError="showError" class-button="right-0" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Resource from '~/common/api/resource'
import Playlist from '~/components/mlearn/playlist/index.vue'
const playlistFileResource = new Resource('library/playlist/file')
export default {
  components: {
    Playlist
  },
  props: {
    item: {
      type: Object,
      default () {
        return null
      }
    },
    playlist: {
      type: Array,
      default () {
        return []
      }
    },
    slug: {
      type: String,
      default () {
        return ''
      }
    },
    viewList: {
      type: Boolean,
      default () {
        return true
      }
    },
    action: {
      type: Boolean,
      default () {
        return true
      }
    }
  },
  data () {
    return {
      textNotify: '',
      open: false,
      processing: false
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  methods: {
    addData (form) {
      const message = `Đã lưu vào  "<b>${form.playlist_name}</b>"`
      if (form.playlist_id) {
        delete form.playlist_name
      }
      if (this.processing) { return }
      this.processing = true
      playlistFileResource.store(form)
        .then((res) => {
          if (res.status === 200) {
            this.open = false
            // eslint-disable-next-line vue/no-mutating-props
            this.item.is_saved = true
            this.textNotify = message
            this.showNotify()
          }
        })
        .finally(() => {
          this.processing = false
        })
    },
    showError (error) {
      this.textNotify = error
      this.showNotify()
    },
    UnSave () {
      if (this.processing) { return }
      this.processing = true
      playlistFileResource.destroy(this.item.id)
        .then((res) => {
          // eslint-disable-next-line vue/no-mutating-props
          this.item.is_saved = false
          this.textNotify = `Đã bỏ lưu "<b>${this.item.name}</b>"`
          this.showNotify()
        })
        .finally(() => {
          this.processing = false
        })
    },
    showNotify () {
      this.$emit('createdPlaylist')
      this.$emit('showNotify', this.textNotify)
    },
    redirectDetail () {
      if (this.slug) {
        this.$helper.checkUserBuySub(this, this.item.is_free, '/tai-lieu/' + this.slug)
      } else {
        this.$helper.checkUserBuySub(this, this.item.is_free, '/tai-lieu/' + this.item.slug)
      }
    },
    openDropdown () {
      if (!this.user) { // chưa đăng nhập
        this.$router.push('/user/login')
        return
      }
      if (!this.action) {
        this.redirectDetail()
        return
      }
      if (this.item.is_saved) {
        this.UnSave()
        return
      }
      this.open = true
    }
  }
}
</script>

<style lang="scss" scoped>
.document-item-list{
  background: var(--white);
  border: 1px solid #CED4DA;
  border-radius: 8px;
  padding:18px 16px 12px 16px;
  @media (max-width:576px) {}
  &:hover{
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    .document-name{
      color: #004390;
    }
  }
  .form-data{
    img{
      margin-right: 12px;
      @media (max-width:576px) {}
    }
    .document-name{
      white-space: break-spaces !important;
      font-weight: 600;
      min-height:50px;
      font-size: 16px;
      -webkit-line-clamp: 2;
      @media (max-width:576px) {
        min-height:45px;
        font-size: 14px;
        -webkit-line-clamp: 1;
      }
    }
  }
  .document-line{
    height: 0;
    border: 0;
    border-top: 1px solid #EBEBEB;
    margin-top:10px;
    margin-bottom:16px;
    @media (max-width:576px) {
      margin-top:5px;
      margin-bottom:8px;
    }
  }
  .document-subtitle{
    font-size: 13px;
    font-weight: 500;
    color: #004390;
  }
  .document-source{
    font-size: 12px;
    color: #868789;
  }
  .document-action{
    border: 1px solid #868789 !important;
    border-radius: 22px;
    padding:6px 0;
    width: 85px;
    font-size: 13px;
    color: #868789;
    @media (max-width:576px) {
    }
    &.active{
      border-color: #004390 !important;
      color: #004390;
    }
  }
}
.document-item-detail{
  background: var(--white);
  padding:12px;
  @media (max-width:576px) {
    padding:10px 0;
  }
  &:hover{
    .document-name{
      color: #004390;
    }
  }
  .file-type{
    width: 62px;
    @media (max-width:576px) {
      width: 30px;
    }
  }
  .form-data{
    .content{
      .lock{
        width:16px;
        margin-left: 10px;
      }
      .document-name{
        white-space: break-spaces !important;
        font-weight: 600;
        font-size: 16px;
        @media (max-width:576px) {
          font-size: 14px;
        }
      }
      .document-subtitle{
        font-weight: 500;
        color: #004390;
        margin-top: 5px;
        font-size: 14px;
        @media (max-width:576px) {
          margin-top: 2px;
          font-size: 13px;
        }
      }
      .document-source{
        font-size: 12px;
        color: #868789;
        margin-top: 10px;
        @media (max-width:576px) {
          margin-top: 4px;
        }
      }
    }
    .action{
      min-width: 40px;
      font-size: 14px;
      text-align: center;
      color: #828282;
      @media (max-width:576px) {}
      &.active{
        color: #004390;
      }
    }
  }
}
</style>
<style lang="scss">
.document-item-detail{
  .action{
    svg{
      width:12px;
      height:16px;
    }
  }
}
</style>
